<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <div class="d-flex justify-space-between">
      <header-filters
        class-name="dashboard"
        :filter-names="['make', 'model']"
        is-date-show
        :date-selected="false"
        :date-validation="false"
        @onChange="onFiltersChange"
      >
        <!-- <v-card>
          <v-select
            :value="selectedCards"
            dense
            :loading="isLoadingMdModels"
            :items="getCardOptions"
            item-value="id"
            item-text="card_name"
            :label="$t('card_options')"
            multiple
            return-object
            @change="onCardOptions"
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">{{ item.card_name }}</span>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ selectedCards.length - 1 }} others)
              </span>
            </template>
          </v-select>
        </v-card> -->
      </header-filters>
      <div>
        <card-options :tenant-tech="vehicle_tech" />
      </div>
    </div>
    <!--  -->
    <section class="sections-wrapper">
      <section-one
        v-if="dashboadSortData?.section_1?.length > 0"
        :loading="isLoading"
        :options="options"
        :vehicle-tech="vehicle_tech"
      />
      <section-two
        v-if="dashboadSortData?.section_2?.length > 0"
        :loading="isLoading"
        :options="options"
        :vehicle-tech="vehicle_tech"
      />
      <section-three
        v-if="dashboadSortData?.section_3?.length > 0"
        :loading="isLoading"
        :options="options"
        :vehicle-tech="vehicle_tech"
      />
    </section>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DashboardDashboard",
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
    SectionOne: () => import("./sections/SectionOne.vue"),
    SectionTwo: () => import("./sections/SectionTwo.vue"),
    SectionThree: () => import("./sections/SectionThree.vue"),
    //
    CardOptions: () => import("./utils/CardOptions.vue"),
  },
  data() {
    return {
      options: {},
      headers: [
        {
          text: this.$t("fuel_vehicle_cost"),
          align: "left",
          sortable: false,
          value: "fuel_cost",
          // width: "5%",
        },
        {
          text: this.$t("electric_vehicle_cost"),
          align: "left",
          sortable: false,
          value: "electricity_cost",
          // width: "15%",
        },
        {
          text: this.$t("savings"),
          align: "left",
          sortable: false,
          value: "savings",
          // width: "15%",
        },
      ],
      //
      isActiveModal: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantsList",
      isLoadingMdModels: "mdModels/isLoadingMdModels",
      listMdModels: "mdModels/listMdModels",
      isLoading: "dashboard/getIsLoading",
      //
      dashboadData: "dashboard/getDashboadData",
      dashboadSortData: "dashboard/getDashboadSortData",
      //
      selectedCards: "dashboard/getSelectedCards",
      getCardOptions: "dashboard/getCardOptions",
    }),

    fleetDataTable() {
      let arr = [];
      arr.push(this.dashboadData);
      return arr;
    },
    isTwoWheeler() {
      const vType = this.dashboadData.vehicle_type;
      return vType?.length > 0 ? vType.every((r) => r == "two_wheeler") : false;
    },
    getWheelerIcon() {
      const vType = this.dashboadData.vehicle_type;
      // return vType?.includes("two_wheeler");
      const isTwoWheel = vType?.includes("two_wheeler");
      const isThreeWheel = vType?.includes("three_wheeler");
      const isFourWheeler = vType?.includes("four_wheeler");
      if (isTwoWheel && isThreeWheel) return "Both_wheeler";
      else if (isTwoWheel) return "two_wheeler";
      else if (isThreeWheel) return "ev_three_wheeler";
      else if (isFourWheeler) return "four_wheeler";
      else return "no-bike";
    },

    //
    getTotalTrip() {
      const arr = [];

      // if (this.options.product == "fleet") {
      arr.push({
        key: "Total Active Trips",
        value: this.dashboadData?.active_trips,
        callBack: true,
      });
      arr.push({
        key: "Active Distance",
        value: this.dashboadData?.active_distance,
        si: "(KM)",
      });
      // }
      arr.push({ key: "Total Trips", value: this.dashboadData?.all_trips });
      arr.push({
        key: "Total Distance",
        value: this.dashboadData?.total_distance,
        si: "(KM)",
      });
      return arr;
    },
    getActiveTrip() {
      const arr = [];
      arr.push({
        key: "Total Trips",
        value: this.dashboadData?.active_trips,
        callBack: true,
      });
      arr.push({
        key: "Active Distance",
        value: this.dashboadData?.active_distance,
        si: "(KM)",
      });
      return arr;
    },

    //
    vehicle_tech() {
      if (this.authUser.isAdmin) {
        const tId = this.options.tenant_id;
        return this.tenantsList.find((r) => r.id == tId)?.vehicle_tech || "ev"; //when nothing set ev
      } else {
        return this.authUser.user_tech;
      }
    },
  },
  watch: {
    "options.tenant_id": {
      handler: async function (v) {
        if (v) {
          const params = {
            tenant_id: this.options.tenant_id,
          };
          await this.$store.dispatch("mdModels/list", params);
          await this.getVehicleList();
        } else {
          this.$store.commit("mdModels/SET_MODEL", []);
        }
      },
    },
    "options.fleetId": {
      handler: async function (v) {
        if (v) {
          const params = {
            tenant_id: this.options.fleetId,
          };
          await this.$store.dispatch("mdModels/list", params);
          await this.getVehicleList();
        } else {
          this.$store.commit("mdModels/SET_MODEL", []);
        }
      },
    },

    // "options.md_model_id": {
    //   handler: async function () {
    //     this.fetchData();
    //   },
    // },
  },
  beforeDestroy() {
    const params = {
      name: "dashboard",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  async mounted() {},
  methods: {
    async fetchData() {
      const params = {};
      params.vehicle_tech = this.vehicle_tech;

      await this.$store.dispatch("dashboard/dashboardCardOptions", params);
      await this.$store.dispatch("dashboard/userDashboardCards", {
        ...params,
        dashboard_type: "customized",
      });

      await this.$store.dispatch("dashboard/list", this.options);
    },
    onFiltersChange(v) {
      if (v.tenant_id !== this.options.tenant_id) {
        delete this.options.md_model_id;
      }
      delete this.options.product;
      delete this.options.fleetId;
      delete this.options.oem_b2b_ids;
      delete this.options.tenant_id;
      this.options = { ...this.options, ...v };
      // this.options = { ...v };
      // if (v.tenant_id) {
      //   this.onTenantChange();
      // }
      this.fetchData();
    },

    async getVehicleList() {
      const params = { ...this.options, vehicles_mqtt_data: true };
      await this.$store.dispatch("vehicles/list", params);
    },
    // async onTenantChange() {
    //   await this.$store.dispatch("mdModels/list", this.options);
    //   // if (this.authUser.isAdmin || this.authUser.user_nature == "fleet") {
    //   // }
    // },
    // onDateChange(v) {
    //   this.options.date = v;
    //   this.fetchData();
    // },

    goToTelematics(val) {
      const params = {};
      if (this.authUser.isAdmin) {
        if (this.options.product == "oem") {
          params.product = this.options.product;
          params.tenant_id = this.options.tenant_id;
        } else if (this.options.product == "fleet") {
          params.product = this.options.product;
          params.fleetId = this.options.fleetId;
          params.oem_b2b_ids = this.options.oem_b2b_ids;
          params.tenant_id = this.options.tenant_id;
        }
      } else if (this.authUser.user_nature == "fleet") {
        params.tenant_id = this.options.tenant_id;
      }
      //
      if (val) {
        params.status = val;
      }
      return this.$router.push({
        name: "vehicle_telematics",
        query: params,
      });
    },

    activeTripAction() {
      // add  parameter to get selected row
      this.isActiveModal = true;
    },

    //
    // async onCardOptions(v) {
    //   const params = {};
    //   if (v.length > this.selectedCards.length) {
    //     const val = v[v.length - 1];
    //     params.card_id = val.id;
    //     params.user_card_id = val.card_id;
    //     params.status = "on";
    //     params.section = val.section;
    //     params.card_color_code = val.card_color_code;
    //     params.sort_order = val.sort_order;
    //     params.vehicle_tech = this.vehicle_tech;
    //   } else if (v.length < this.selectedCards.length) {
    //     const ids = v.map((r) => r.id);
    //     const val = this.selectedCards.find((r) => !ids.includes(r.id));
    //     params.card_id = val.id;
    //     params.user_card_id = val.card_id;
    //     params.status = "off";
    //     params.section = val.section;
    //     params.card_color_code = val.card_color_code;
    //     params.sort_order = val.sort_order;
    //     params.vehicle_tech = this.vehicle_tech;
    //   }
    //   await this.$store.dispatch("dashboard/updateCard", params);
    // },
  },
};
</script>
<style lang="sass" scoped>

#dashboard
  padding: 2rem 2.5rem

.sections-wrapper
  display: flex
  gap: 1rem




:deep .v-select
  .v-select__selections
    span
      width: 70px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis


@media only screen and (max-width: 960px)
  .sections-wrapper
    flex-direction: column

//   #content-wrapper
//     .content-container
//       flex-direction: column
//       .box-container
//         order: 2
//       .tags-container
//         order: 3
//       .mapAndTop5
//         order: 1
</style>
/* Small devices (portrait tablets and large phones, 600px and up) */ @media
only screen and (min-width: 600px) { .box { width: 250px !important; } }
